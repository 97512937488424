


/*===============================
    40.MEET US css 
================================*/


.meet-us-area{
    padding-bottom: 120px;
    & .meet-us-item{
        height: 300px;
        padding-left: 100px;
        padding-right: 100px;
        @media #{$xs} {
            padding-left: 30px;
            padding-right: 30px;
            display: block !important;
            padding-top: 40px;
        }
        & .title{
            font-size: 50px;
            color: $white;
            line-height: 60px;
            @media #{$md} {
                font-size: 28px;
                line-height: 40px;
            }
            @media #{$xs} {
                font-size: 24px;
                line-height: 34px;
            }
            @media #{$sm} {
                font-size: 40px;
                line-height: 50px;
            }
        }
        & a{
            font-size: 15px;
            line-height: 60px;
            padding: 0 40px;
            background: $theme-color;
            color: $white;
            border-color: $theme-color;
            @media #{$xs} {
                margin-top: 30px;
            }
            & i{
                padding-left: 5px;
            }
        }
    }
}
