


/*===========================
    17.ACTION 2 css 
===========================*/
.intro-overlay{
	background-color: rgba(0, 21, 45,.9);
	padding-top: 90px;
	padding-bottom: 145px;
	.news-area{
		// background-color: rgba(0, 21, 45,.9);
		// padding-top: 90px;
		// padding-bottom: 145px;
		& .section-title-2{
			& .title{
				color: $white;
			}
			& p{
				color: $white;
			}
		}
		& .single-news{
			position: relative;
			overflow: hidden;
			
			& img{
				width: 100%;
				transform: scale(1) rotate(0deg);
				@include transition(0.3s);
			}
			& .single-news-overlay{
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background-color: rgba(0, 21, 46,.4);
				@include transition(0.3s);
				padding: 240px 40px 37px;
				@media #{$lg} {
					padding: 175px 40px 37px;
				}
				@media #{$md} {
					padding: 205px 40px 37px;
				}
				@media #{$xs} {
					padding: 140px 20px 20px;
				}
				@media #{$sm} {
					padding: 260px 20px 20px;
				}
				& span{
					font-weight: 700;
					color: $white;
					font-size: 14px;
				}
				& .title{
					font-size: 24px;
					padding-top: 10px;
					@media #{$lg} {
						font-size: 20px;
					}
					& a{
						color: $white;
					}
				}
				& > a{
					color: $white;
					margin-top: 180px;
					font-size: 30px;
					@media #{$lg} {
						margin-top: 105px;
					}
					@media #{$md} {
						margin-top: 135px;
					}
					@media #{$xs} {
						margin-top: 85px;
					}
					@media #{$sm} {
						margin-top: 185px;
					}
				}
			}
			&:hover{
				& img{
					transform: scale(1.2) rotate(5deg);
				}
				& .single-news-overlay{
					padding: 37px 40px 37px;
					background-color: rgba(0, 109, 232,.9);
					@media #{$xs} {
						padding: 20px;
					}
				}
			}
		}
		&.news-area-2{
			padding-top: 90px;
		}
	}
}


