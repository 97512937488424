


/*===========================
    18.FEATURES css 
===========================*/


.features-area{
	& .features-item{
		border: 16px solid rgb(245, 250, 255);
		padding: 92px 85px 100px 535px;
		position: relative;
		@media #{$lg} {
			padding: 92px 85px 100px 345px;
		}
		@media #{$md} {
			padding: 92px 85px 100px 100px;
		}
		@media #{$xs} {
			padding: 20px;
			border-width: 10px;
		}
		@media #{$sm} {
			padding: 50px;
		}
		& strong{
			font-weight: 700;
			color: $theme-color;
			letter-spacing: 2px;
			text-transform: uppercase;
			padding-bottom: 8px;
			display: block;
		}
		& .title{
			font-size: 70px;
			line-height: 80px;
			padding-bottom: 15px;
			@media #{$lg} {
				font-size: 62px;
				line-height: 70px;
			}
			@media #{$md} {
				font-size: 62px;
				line-height: 70px;
			}
			@media #{$xs} {
				font-size: 28px;
				line-height: 38px;
			}
			@media #{$sm} {
				font-size: 43px;
				line-height: 53px;
			}
		}
		& span{
			font-size: 24px;
			color: #6f8aa9;
			padding-bottom: 25px;
			@media #{$xs} {
				font-size: 18px;
			}
			@media #{$sm} {
				font-size: 16px;
			}
		}
		& p{
			color: #69a1bb;
		}
		& ul{
			margin-top: 35px;
			display: flex;
			& li{
				display: inline-block;
				& a{
					background: $theme-color;
					border-radius: 0;
					color: $white;
					margin-right: 20px;
					border-color: $theme-color;
					@media #{$xs} {
						padding: 0 30px;
						margin-bottom: 20px;
					}
					@media #{$sm} {
						padding: 0 30px;
					}
					& i{
						padding-right: 6px;
					}
					&:hover{
						background: #b7c3d6;
						border-color: #b7c3d6;
					}
					&.main-btn-2{
						background: #b7c3d6;
						border-color: #b7c3d6;
						&:hover{
							background: $theme-color;
							border-color: $theme-color;
						}
					}		
				}
			}
		}
		& img{
			position: absolute;
			top: -15px;
			left: -15px;
			width: 466px;
			@media #{$lg} {
				width: 320px;
			}
		}
	}
	& .single-features{
		border: 16px solid rgb(245, 250, 255);
		padding: 45px 38px;
		@media #{$lg} {
			padding: 45px 24px;
		}
		@media #{$xs} {
			padding: 30px 15px;
			border-width: 10px
		}
		& .title{
			font-size: 36px;
			padding-top: 32px;
			padding-bottom: 20px;
			@media #{$lg} {
				font-size: 28px;
			}
			@media #{$md} {
				font-size: 30px;
			}
			@media #{$xs} {
				font-size: 30px;
			}
		}
		& a{
			color: #81a3bb;
			font-weight: 700;
			@include transition(0.3s);
			margin-top: 18px;
			& i{
				padding-right: 3px;
			}
			&:hover{
				color: $theme-color;
			}
		}
	}
	& .features-sub{
		padding: 60px 60px 71px;
		background: #006de8;
		@media #{$lg} {
			padding: 60px 24px 93px;
		}
		@media #{$md} {
			padding: 60px 30px 93px;
		}
		@media #{$xs} {
			padding: 20px;
		}
		& .title{
			text-transform: capitalize;
			color: $white;
			font-size: 30px;
			padding-bottom: 7px;
		}
		& p{
			color: $white;
			padding-bottom: 25px;
		}
		& .input-box{
			& input{
				background-color: transparent;
				border: 2px solid rgba(255, 255, 255,.3);
				width: 100%;
				line-height: 60px;
				padding-left: 30px;
				color: $white;
				font-size: 14px;
				&::placeholder{
					font-size: 14px;
					opacity: 1;
					color: $white;
				}
			}
			& button{
				background: #005ec9;
				color: $white;
				border-color: #005ec9;
				border-radius: 0;
				margin-top: 10px;
				width: 100%;
				padding: 0;
			}
		}
	}
}


