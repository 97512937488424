


/*===========================
    20.PORTFOLIO 3 css 
===========================*/


.portfolio-3-area{
	& .section-title{
		& .title{
			@media #{$lg} {
				font-size: 60px;
				line-height: 70px;
			}
			@media #{$md} {
				font-size: 62px;
				line-height: 70px;
			}
			&::before{
				display: none;
			}
		}
	}
	& .single-portfolio{
		box-shadow: 0px 8px 16px 0px rgba(60, 110, 203, 0.1);
		& .portfolio-content{
			padding: 35px 33px;
			@media #{$lg} {
				padding: 35px 20px;
			}
			@media #{$xs} {
				padding: 20px 20px;
			}
			& span{
				font-size: 14px;
				text-transform: uppercase;
				letter-spacing: 2px;
				color: $theme-color;
				font-weight: 700;
			}
			& a{
				& .title{
					font-size: 23px;
					line-height: 34px;
					@media #{$lg} {
						font-size: 19px;
						line-height: 28px;
					}
					@media #{$md} {
						font-size: 20px;
						line-height: 26px;
					}
					@media #{$xs} {
						font-size: 18px;
						line-height: 28px;
					}
					@media #{$sm} {
						font-size: 18px;
						line-height: 28px;
					}
				}
			}
		}
	}
}

