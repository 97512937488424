


/*===============================
    36.CASE STUDIES css 
================================*/


.case-studies-area{
	padding-top: 112px;
	padding-bottom: 112px;
    overflow: hidden;
	& .section-title{
		& span{
			letter-spacing: 3px;
		}
		& .title{
			font-size: 55px;
			line-height: 65px;
            padding-bottom: 5px;
            @media #{$lg} {
                font-size: 44px;
                line-height: 54px;
            }
            @media #{$md} {
                font-size: 44px;
                line-height: 54px;
                padding-right: 200px;
            }
            @media #{$xs} {
                font-size: 30px;
                line-height: 40px;
            }
            @media #{$sm} {
                font-size: 36px;
                line-height: 46px;
                padding-right: 120px;
            }
			&::before{
				display: none;
			}
		}
	}
	& .single-case-studies{
		position: relative;
        margin-left: 8px;
        margin-right: 8px;
		& img{
			width: 100%;
		}
		& .case-overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
			
			& span{
				color: $white;
				font-weight: 700;

			}
			& .title{
				font-size: 26px;
				color: $white;
				line-height: 36px;
			}
			& a{
				position: absolute;
				right: 0;
				bottom: 0;
				background: $theme-color;
				color: $white;
				font-size: 20px;
				height: 60px;
				width: 60px;
				text-align: center;
				line-height: 60px;
			}
            & .item{
                position: absolute;
                left: 50px;
                bottom: 50px;
                @media #{$laptop} {
                    bottom: 60px;
                    left: 20px;
                }
                @media #{$lg} {
                    bottom: 70px;
                    left: 20px;
                }
                @media #{$md} {
                    bottom: 70px;
                    left: 20px;
                }
                @media #{$xs} {
                    bottom: 70px;
                    left: 20px;
                }
            }

		}
	}
    & .case-studies-active{
        & .col-lg-3{
            padding-left: 0;
            padding-right: 0;
        }
        & .slick-arrow{
            position: absolute;
            top: -110px;
            right: 20px;
            height: 60px;
            width: 60px;
            text-align: center;
            line-height: 58px;
            border: 2px solid $border-color-2;
            border-radius: 50%;
            color: $theme-color;
            background: $white;
            font-size: 20px;
            cursor: pointer;
            @include transition(0.3s);
            &:hover{
                background: $theme-color;
                color: $white;
                border-color: $theme-color;
            }
            &.next{
                right: 305px;
                @media #{$laptop} {
                    right: 20px;
                }
                @media #{$lg} {
                    right: -40px;
                }
            }
            &.prev{
                right: 380px;
                @media #{$laptop} {
                    right: 95px;
                }
                @media #{$lg} {
                    right: 40px;
                }
            }
        }
    }
    & .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }
}


