


/*===============================
    25.SERVICES OPTIMIZATION css 
================================*/


.services-optimization-area{
	& .services-optimization-thumb-2{
		margin-top: -170px;
		margin-right: 20px;
		text-align: right;
		@media #{$xs} {
			margin-top: 0;

		}
		@media #{$sm} {
			margin-top: -170px;
			
		}
		& img{
			border: 14px solid $white;
		}
	}
	& .services-optimization-content{
		& > span{
			color: $theme-color;
			font-weight: 700;
			letter-spacing: 2px;
			position: relative;
			padding-bottom: 28px;
			&::before{
				position: absolute;
				content: '';
				top: 10px;
				right: -90px;
				height: 2px;
				width: 70px;
				background: $theme-color;
				@media #{$xs} {
					display: none;
				}
			}
		}
		& .title{
			font-size: 60px;
			line-height: 70px;
			padding-bottom: 35px;
			@media #{$xs} {
				font-size: 42px;
				line-height: 50px;
			}
			@media #{$sm} {
				font-size: 60px;
			}
		}
		& > p{
			padding-right: 100px;
			color: #000000;

			@media #{$xs} {
				padding-right: 0;
			}
		}
		& .action-support-item{
			margin-top: 40px;
			background: #ff5f6e;
			text-align: center;
			padding: 20px 40px;
			position: relative;
			display: inline-block;
			z-index: 5;
			& i{
				position: absolute;
				top: 8px;
				left: 10px;
				opacity: .05;
				color: $white;
				font-size: 60px;
				z-index: -1;
			}
			& p{
				color: $white;
				font-size: 14px;
			}
			& span{
				color: $white;
				font-weight: 700;
				font-size: 23px;
				@media #{$lg} {
					font-size: 18px;
				}
				@media #{$sm} {
					font-size: 18px;
				}
			}
		}
	}
}



