


/*===========================
    13.WHAT WE DO css 
===========================*/

.what-we-do-area{
	& .what-we-do-item{
		background: $white;
		box-shadow: 0px 8px 16px 0px rgba(0, 109, 232, 0.04);
		& a{
			height: 100px;
			padding: 0 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include transition(0.3s);
			position: relative;
			z-index: 5;
			& h6{
				font-size: 20px;
				@include transition(0.3s);

			}
			& i{
				font-size: 20px;
				color: $text-color-2;
				@include transition(0.3s);
			}
			&:hover{
				background: $theme-color;
				& h6{
					color: $white;
				}
				& i{
					color: $white;
				}
			}
			&::before{
				position: absolute;
				content: '\f41b';
				font-family: 'Font Awesome 5 Brands';
				font-size: 80px;
				top: -20px;
				left: 5px;
				color: #fff;
				z-index: -1;
				opacity: .1;
			}
		}
	}
}

