


/*===========================
    24.SERVICES ITEM css 
===========================*/

.services-item-area{
	& .section-title{
		& .title{
			font-size: 60px;
			margin-bottom: 13px;
			@media #{$xs} {
				font-size: 30px;
			}
			@media #{$sm} {
				font-size: 46px;
			}
			&::before{
				display: none;
			}

		}
			& p{
				padding: 0 22px 45px;
			}
	}
	& .single-services-item{
		position: relative;
		overflow: hidden;
		& img{
			width: 100%;
		}
		& .services-overlay{
			position: absolute;
			bottom: -110px;
			left: 0;
			width: 100%;
			height: 180px;
			background-color: rgba(0, 34, 73,.7);
			padding: 20px 40px 40px;
			@include transition(0.3s);
			@media #{$lg} {
				padding: 20px 10px 40px;
			}
			@media #{$md} {
				padding: 20px 30px 40px;
			}
			@media #{$xs} {
				padding: 20px 5px 40px;
			}
			@media #{$sm} {
				padding: 20px 26px 40px;
			}
			& i{
				position: absolute;
				top: -20px;
				left: 20px;
				height: 70px;
				width: 70px;
				background: $theme-color;
				text-align: center;
				line-height: 70px;
				color: $white;
				font-size: 30px;
			}
			& p{
				color: $white;
				font-size: 14px;
				padding-top: 20px;
			}
			& .title{
				padding-left: 80px;
				color: $white;
				font-size: 20px;
				@media #{$lg} {
					padding-left: 90px;
				}
				@media #{$xs} {
					padding-left: 95px;
					font-size: 18px;
				}
				@media #{$sm} {
					padding-left: 80px;
					font-size: 20px;
				}
			}
		}
		&:hover{
			& .services-overlay{
				bottom: 0;
				background: rgba(0,102,255,.9);
				@media #{$sm} {
					bottom: -20px;
				}
			}
		}
	}
}




